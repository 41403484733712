<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-row>
        <v-card
          flat
        >
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">NEW ACCOMPLISHMENT</h4>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-select
                  dense
                  outlined
                  v-model="type_of_sector"
                  :items="['Health','Education','Community','Livelihood','Youth','Environment','Protection']"
                  label="Type of Sector"
                  required
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="name_of_the_project"
                  label="Name of the Project"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>

              <v-col
                md="4"
                cols="12"
              >
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="location"
                  label="LOCATION"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="date_of_project"
                  label="Date of Project"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="recipients"
                  label="Recipients"
                  dense
                  outlined
                  :rules="rules.default_max_100_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="expenses"
                  dense
                  label="Expenses"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="narratives"
                  dense
                  label="Narratives"
                  outlined
                  :rules="rules.default_no_empty_rule"
                ></v-textarea>
              </v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-row>
                    <v-col md="12" cols="12" class="mt-4">
                      <vue-croppie width="100%" cover ref="croppieRef" :enableOrientation="true"
                                   :mouseWheelZoom="false"
                                   :boundary="{ width: 450, height: 450}"
                                   :viewport="{ width:425, height:425, 'type':'square' }">
                      </vue-croppie>
                    </v-col>
                    <v-col md="12" cols="12">
                      <!-- upload photo -->
                      <div>
                        <v-btn color="primary" class="me-3 mt-5"
                               @click="$refs.refInputEl.click()">
                          <v-icon class="d-sm-none">
                            {{ icons.mdiCloudUploadOutline }}
                          </v-icon>
                          <span class="d-none d-sm-block">Upload Photo 1</span>
                        </v-btn>

                        <input
                          ref="refInputEl"
                          type="file"
                          accept=".jpeg,.jpg"
                          :hidden="true"
                          v-on:click="$refs.refInputEl.value = ''"
                          v-on:change="croppie"
                        />

                        <v-btn color="error" outlined class="mt-5" @click="resett1()"> Reset
                        </v-btn>
                        <p class="text-sm mt-5">Allowed JPG or JPEG Only</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" cols="12">
                  <v-row>
                    <v-col md="12" cols="12" class="mt-4">
                      <vue-croppie width="100%" cover ref="croppieRef2" :enableOrientation="true"
                                   :mouseWheelZoom="false"
                                   :boundary="{ width: 450, height: 450}"
                                   :viewport="{ width:425, height:425, 'type':'square' }">
                      </vue-croppie>
                    </v-col>
                    <v-col md="12" cols="12">
                      <!-- upload photo -->
                      <div>
                        <v-btn color="primary" class="me-3 mt-5"
                               @click="$refs.refInputEl2.click()">
                          <v-icon class="d-sm-none">
                            {{ icons.mdiCloudUploadOutline }}
                          </v-icon>
                          <span class="d-none d-sm-block">Upload Photo 2</span>
                        </v-btn>

                        <input
                          ref="refInputEl2"
                          type="file"
                          accept=".jpeg,.jpg"
                          :hidden="true"
                          v-on:click="$refs.refInputEl2.value = ''"
                          v-on:change="croppie2"
                        />

                        <v-btn color="error" outlined class="mt-5" @click="resett2()"> Reset
                        </v-btn>
                        <p class="text-sm mt-5">Allowed JPG or JPEG Only</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" cols="12">
                  <v-row>
                    <v-col md="12" cols="12" class="mt-4">
                      <vue-croppie width="100%" cover ref="croppieRef3" :enableOrientation="true"
                                   :mouseWheelZoom="false"
                                   :boundary="{ width: 450, height: 450}"
                                   :viewport="{ width:425, height:425, 'type':'square' }">
                      </vue-croppie>
                    </v-col>
                    <v-col md="12" cols="12">
                      <!-- upload photo -->
                      <div>
                        <v-btn color="primary" class="me-3 mt-5"
                               @click="$refs.refInputEl3.click()">
                          <v-icon class="d-sm-none">
                            {{ icons.mdiCloudUploadOutline }}
                          </v-icon>
                          <span class="d-none d-sm-block">Upload Photo 3</span>
                        </v-btn>

                        <input
                          ref="refInputEl3"
                          type="file"
                          accept=".jpeg,.jpg"
                          :hidden="true"
                          v-on:click="$refs.refInputEl3.value = ''"
                          v-on:change="croppie3"
                        />

                        <v-btn color="error" outlined class="mt-5" @click="resett3()"> Reset
                        </v-btn>
                        <p class="text-sm mt-5">Allowed JPG or JPEG Only</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-col cols="12"
                     md="12">
                <v-card>
                  <v-toolbar-title>
                    <h5 class="font-weight-light">EXPENSES BREAKDOWN
                      <v-icon
                        class="mr-2"
                        color="error"
                        @click="expense_dialog=true"
                      >
                        {{icons.mdiCash}}
                      </v-icon>
                    </h5>
                  </v-toolbar-title>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-center text-uppercase">
                          Particulars
                        </th>
                        <th class="text-center text-uppercase">
                          Description
                        </th>
                        <th class="text-center text-uppercase">
                          Amount
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="item in list_of_expenses"
                        :key="item.id"
                      >
                        <td class="text-center">
                          {{ item.particulars }}
                        </td>
                        <td class="text-center">
                          {{ item.description }}
                        </td>
                        <td class="text-center">
                          {{ formatPrice(item.amount) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col cols="12"
                     md="12">
                <v-card>
                  <v-toolbar-title>
                    <h5 class="font-weight-light">LIST OF DONORS/SPONSORS
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="donors_dialog=true"
                      >
                        {{icons.mdiAccountPlus}}
                      </v-icon>
                    </h5>
                  </v-toolbar-title>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-center text-uppercase">
                          Date
                        </th>
                        <th class="text-center text-uppercase">
                          Name
                        </th>
                        <th class="text-center text-uppercase">
                          Description
                        </th>
                        <th class="text-center text-uppercase">
                          Amount / In Kind
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="item in list_of_donors"
                        :key="item.id"
                      >
                        <td class="text-center">
                          {{ item.date }}
                        </td>
                        <td class="text-center">
                          {{ item.name }}
                        </td>
                        <td class="text-center">
                          {{ item.kind }}
                        </td>
                        <td class="text-center">
                          {{ item.kind==='Money'?formatPrice(item.amount):item.amount }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col cols="12"
                     md="12">
                <v-card>
                  <v-toolbar-title>
                    <h5 class="font-weight-light">LIST OF
                      VOLUNTEERS/PARTICIPANTS
                      <v-icon
                        class="mr-2"
                        color="warning"
                        @click="volunteers_dialog=true"
                      >
                        {{icons.mdiAccountPlus}}
                      </v-icon>
                    </h5>
                  </v-toolbar-title>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-center text-uppercase">
                          Name
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="item in list_of_volunteers"
                        :key="item.id"
                      >
                        <td class="text-center">
                          {{ item.name }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="save_member"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="donors_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ADD DONORS/SPONSORS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="date_donated"
                label="Date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-checkbox
              v-model="is_registered"
              label="Is Registered?"
              class="mt-4"
              hide-details
              @change="reset_donor_info()"
            ></v-checkbox>
            <v-col
              md="5"
              cols="12"

            >
              <v-combobox
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="name_of_sponsor"
                @keyup="searching($event.target.value)"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Name of Donors/Sponsor"
                ref="memberCombobox"
                dense
                outlined
                v-if="is_registered"
              ></v-combobox>
              <v-text-field
                v-else
                v-model="name_of_sponsor"
                label="Name of Donors/Sponsor"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <v-select
                v-model="kind"
                dense
                outlined
                label="Kind"
                :items="['Money','Others']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="3"
              v-if="kind==='Others'"
            >
              <v-text-field
                v-model="kind_donated"
                dense
                label="Specify"
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              v-if="kind==='Money'"
            >
              <v-text-field
                v-model="donate_amount"
                dense
                label="Amount"
                outlined
                type="number"
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add_donors">Save</v-btn>
          <v-btn color="error" @click="donors_dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="volunteers_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ADD VOLUNTEERS/PARTICIPANTS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-checkbox
              v-model="is_registered"
              label="Is Registered?"
              class="mt-4"
              hide-details
              @change="reset_donor_info()"
            ></v-checkbox>
            <v-col
              md="5"
              cols="12"
            >
              <v-combobox
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="name_of_sponsor"
                @keyup="searching($event.target.value)"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Name of Donors/Sponsor"
                ref="memberCombobox"
                dense
                outlined
                v-if="is_registered"
              ></v-combobox>
              <v-text-field
                v-else
                v-model="name_of_sponsor"
                label="Name of Donors/Sponsor"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add_volunteers">Save</v-btn>
          <v-btn color="error" @click="volunteers_dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="expense_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ADD EXPENSES</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-col
              md="2"
              cols="12"
            >
              <v-select
                v-model="exp_particulars"
                dense
                outlined
                label="Particulars"
                :items="['Project Cost','Travel Expense','Food','Accomodation','MISC']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="8"
              cols="12"
            >
              <v-text-field
                v-model="exp_description"
                label="Description"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="2"
              cols="12"
            >
              <v-text-field
                v-model="exp_amount"
                label="Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add_expenses">Save</v-btn>
          <v-btn color="error" @click="expense_dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountPlus, mdiCash} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      croppieImage1: '',
      cropped1: false,
      croppieImage2: '',
      cropped2: false,
      croppieImage3: '',
      cropped3: false,
      avatarImg: require('@/assets/images/avatars/1.png'),

      donors_dialog: false,
      volunteers_dialog: false,

      location: '',
      name_of_the_project: '',
      recipients: '',
      date_of_project: '',
      expenses: 0,
      list_of_donors: [],
      list_of_volunteers: [],
      list_of_expenses: [],

      date_donated: '',
      name_of_sponsor: '',
      kind: '',
      kind_donated: '',
      donate_amount: 0,

      is_registered: true,

      search_member: '',
      type_of_sector: '',
      search_items: [],
      idd_of_donor: 0,
      narratives: '',
      expense_dialog: false,
      exp_particulars: '',
      exp_description: '',
      exp_amount: '',
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      value: Object,
    },
    setup() {
      return {
        show: false,

        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
        },
      }
    },
    mounted() {
      if (this.value != null) {
        if (Object.keys(this.value).length > 0) {
          console.log(this.value)
          this.location = this.value.location
          this.name_of_the_project = this.value.name_of_the_project
          this.recipients = this.value.recipients
          this.date_of_project = this.value.date_of_project
          this.expenses = this.value.expenses
          this.list_of_donors = this.value.donors
          this.list_of_volunteers = this.value.participants
          this.list_of_expenses = this.value.expenses_breakdown
          this.type_of_sector=this.value.type_of_sector
          this.narratives = this.value.narratives
        }
      }
    },
    data() {
      return initialState()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name', 'user_id', 'month_start', 'month_end']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('members_information', ['search_all_members']),
      ...mapActions('accomplishment', ['register_accomplishment', 'update_accomplishment']),
      ...mapActions('system_data', ['change_snackbar']),
      croppie(e) {
        this.cropped = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = true
          this.$refs.croppieRef.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
      },
      resett1() {
        this.$refs.croppieRef.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl.value = ''
        this.cropped = false
        this.croppieImage = ''
        this.alert = false
      },
      croppie2(e) {
        this.cropped2 = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped2 = true
          this.$refs.croppieRef2.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
      },
      resett2() {
        this.$refs.croppieRef2.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl2.value = ''
        this.cropped2 = false
        this.croppieImage2 = ''
        this.alert = false
      },
      croppie3(e) {
        this.cropped3 = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped3 = true
          this.$refs.croppieRef3.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
      },
      resett3() {
        this.$refs.croppieRef3.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl3.value = ''
        this.cropped3 = false
        this.croppieImage3 = ''
        this.alert = false
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        Object.assign(this.$data, initialState())
      },
      reset_donor_info() {
        this.name_of_sponsor = ''
        this.search_member = ''
        this.search_items = []
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      add_donors() {
        var kinnddd = this.kind
        if (this.kind === 'Others') {
          kinnddd = this.kind_donated
          this.donate_amount = 0
        }
        if (this.is_registered) {
          this.list_of_donors.push({
            date: this.date_donated,
            id: this.name_of_sponsor.id,
            name: this.name_of_sponsor.name,
            kind: kinnddd,
            amount: this.donate_amount,
          })
        } else {
          this.idd_of_donor--
          this.list_of_donors.push({
            date: this.date_donated,
            id: this.idd_of_donor,
            name: this.name_of_sponsor.toUpperCase(),
            kind: kinnddd,
            amount: this.donate_amount,
          })
        }

        this.donors_dialog = false
      },
      add_volunteers() {
        if (this.is_registered) {
          this.list_of_volunteers.push({
            id: this.name_of_sponsor.id,
            name: this.name_of_sponsor.name,
          })
        } else {
          this.idd_of_donor--
          this.list_of_volunteers.push({
            id: this.idd_of_donor,
            name: this.name_of_sponsor.toUpperCase(),
          })
        }
        this.volunteers_dialog = false
      },
      add_expenses() {
        this.idd_of_donor--
        this.list_of_expenses.push({
          id: this.idd_of_donor,
          description: this.exp_description.toUpperCase(),
          particulars: this.exp_particulars,
          amount: this.exp_amount,
        })
        this.expenses = parseFloat(this.expenses) + parseFloat(this.exp_amount)

        this.expense_dialog = false
      },
      async save_member() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var flag = false
          if (moment(this.date_of_project, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
            || moment(this.date_of_project, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
            flag = true
          } else {
            var compareDate = moment(this.date_of_project, 'YYYY-MM-DD')
            var startDate = moment(this.month_start, 'MMMM D, YYYY')
            var endDate = moment(this.month_end, 'MMMM D, YYYY')

            flag = compareDate.isBetween(startDate, endDate) //false in this case
          }
          if (flag === false && this.district_id != 15) {
            this.date_of_project = ''
            this.alert = true
            this.alert_message = 'Date is Not Belong in Transaction Month!'
            this.saving = false
          } else {
            let options = {
              type: 'base64',
              format: 'jpeg'
            };
            await this.$refs.croppieRef.result(options, output => {
              this.croppieImage = output;
            });
            await this.$refs.croppieRef2.result(options, output => {
              this.croppieImage2 = output;
            });
            await this.$refs.croppieRef3.result(options, output => {
              this.croppieImage3 = output;
            });

            const data = new FormData()
            data.append('name_of_the_project', this.name_of_the_project.toUpperCase());
            data.append('location', this.location.toUpperCase());
            data.append('date_of_project', this.date_of_project);
            data.append('recipients', this.recipients.toUpperCase());
            data.append('expenses', this.expenses);
            data.append('created_id', this.user_id);
            data.append('district_id', this.district_id);
            data.append('narratives', this.narratives);
            data.append('type_of_sector', this.type_of_sector);
            data.append('list_of_donors', JSON.stringify(this.list_of_donors));
            data.append('list_of_volunteers', JSON.stringify(this.list_of_volunteers));
            data.append('list_of_expenses', JSON.stringify(this.list_of_expenses));

            data.append('photo_1', this.croppieImage);
            data.append('photo_2', this.croppieImage2);
            data.append('photo_3', this.croppieImage3);
            if (this.value != null) {
              if (Object.keys(this.value).length > 0) {
                data.append('id', this.value.id);
                this.update_accomplishment(data)
                  .then(response => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: response.data,
                    })
                    this.$emit('data')
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    console.log(error)
                    this.saving = false
                  })
              }
            } else {
              this.register_accomplishment(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.reset()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  console.log(error)
                  this.saving = false
                })
            }
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
